<template>
  <div class="incoming">
    <van-nav-bar
      title="入库明细"
      left-arrow
      fixed
      safe-area-inset-top
      placeholder
      @click-left="leftClick"
      @click-right="rightClick">
      <span slot="right">来料统计</span>
      <van-icon name="arrow" slot="right" />
    </van-nav-bar>
    <van-pull-refresh
      v-model="refreshing"
      success-text="刷新成功"
      @refresh="onRefresh"
      style="min-height: 100vh;"
    >
      <template v-if="ishow">
        <van-empty
          class="custom-image"
          :image="require('@/assets/images/pic_no information@2x.png')"
          description="暂无数据!"
        />
      </template>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        v-if="!ishow"
      >
          <div
            v-for="(itemslist, index) in incomingList"
            :key="index"
            @click="hrefdetails(itemslist.id)"
          >
            <div class="flex">
              <div class="left" style="background:#39394D">
                <div class="text">来料入库</div>
              </div>
              <div class="right">
                <div class="orderInfo">
                  <div class="flex_between">
                    <h1 class="comeWight" style="width: 220px; overflow:hidden;white-space:nowrap;text-overflow:ellipsis">来料类别 {{ itemslist.goodsNames.join(',') }}</h1>
                    <div class="state stateash" :class="{
                        stateGreen: itemslist.referenceStatus == 'REFERENCED' || itemslist.referenceStatus == 'CONFIRMED' || itemslist.referenceStatus == 'TICHUN' ?true:false,
                        stateYellow:itemslist.referenceStatus == 'NONE' ||itemslist.referenceStatus =='WAIT_CONFIRM' || itemslist.referenceStatus == 'TICHUNING'?true:false
                        }"
                         :style="{color:itemslist.referenceStatus == 'NONE' ||itemslist.referenceStatus =='WAIT_CONFIRM' || itemslist.referenceStatus == 'TICHUNING'?'#ff2d2e'
                        :itemslist.referenceStatus == 'REFERENCED' || itemslist.referenceStatus == 'CONFIRMED' || itemslist.referenceStatus == 'TICHUN'?'#02790D' : '#999999'}"
                    >
                      {{
                        itemslist.referenceStatus == "REJECT"
                          ? "待审核"
                          : itemslist.referenceStatus == "REFERENCED"
                          ? "已处理"
                          : itemslist.referenceStatus == "CONFIRMED"
                          ? "已确认"
                          : itemslist.referenceStatus == "TICHUN"
                          ? "提纯完成"
                          : itemslist.referenceStatus == "TICHUNING"
                          ? "提纯中"
                          :"待确认"
                      }}
                    </div>
                    <!-- <div class="status">{{text}}</div> -->
                  </div>
                  <div class="newWight">来料重量 {{ itemslist.totalWeight }}g</div>
                  <div class="newWight">折足重量 {{ itemslist.totalDiscountWeight }}g</div>
<!--                  <div class="newWight">未开单重量 {{ itemslist.goldFreeWeight ? itemslist.goldFreeWeight : 0 + itemslist.baiyinFreeWeight ? itemslist.baiyinFreeWeight : 0 + itemslist.ptFreeWeight ? itemslist.ptFreeWeight : 0 + itemslist.pdFreeWeight ? itemslist.pdFreeWeight : 0 }}g</div>-->
                </div>
                <div class="orderNmber">
                  <div>
                    <div class="time">
                      检测时间: {{ itemslist.stockTime | formatDate_ }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { incoming } from "@/utils/api";
import { formatDate_ } from "@/utils/filters";
export default {
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      page: 0,
      size: 8,
      ishow: true, // 是否有数据
      incomingList: [] // 换料数据
    };
  },
  filters: {
    formatDate_
  },
  mounted() {
    this.onRefresh()
  },
  methods: {
    // 下拉事件
    async onLoad(){
      // console.log('page2',this.page);
      let res = await incoming({page:this.page,size:this.size})
      this.page = res.number + 1

      this.incomingList.push(...res.content)
      this.loading = false
      if (this.incomingList.length != 0) {
        this.ishow = false;
      } else {
        this.ishow = true;
      }
      if (res.last == true) {
        this.finished = true
      }

    },
    // 下拉事件
    async onRefresh() {
      this.finished = false
      try {
        const res = await incoming({page:0,size:this.size});
        this.incomingList = res.content;
        this.page = res.number + 1
        if (this.incomingList.length != 0) {
          this.ishow = false;
        } else {
          this.ishow = true;
        }
        if (res.last == true) {
          this.finished = true
        }
        this.loading = false
        console.log("this.incomingList", this.incomingList);
        // this.$Loading.close();
      } catch (err) {
        this.ishow = true;
      }
      setTimeout(() => {
        this.refreshing = false
      }, 500);
    },
    hrefdetails(orderId) {
      console.log(orderId);
      this.$router.push({
        name: "wuliaoDetail",
        query: { incomingId: orderId, type: "incoming" }
      });
    },
    // 顶部左侧图标点击事件
    leftClick(){
      this.$router.push({
        name:'person'
      })
    },
    // 顶部右侧押金调整事件
    rightClick(){
      this.$router.push({
        name:'incomingStatistics',
      })
    },
  }
};
</script>

<style lang="scss" scoped="true">
.incoming {
  background-color: #f5f5f5;
}
#loadingbox {
  margin-top: 150px;
}
::v-deep {
  .van-loading__text {
    font-size: 30px;
  }
  .van-list__finished-text {
    font-size: 30px;
  }
  .van-pull-refresh__head {
    font-size: 30px !important;
  }
  .van-empty__image {
    width: 420px;
    height: 260px;
  }
  .van-nav-bar{
    height: 100px;
    .van-nav-bar__content{
      line-height: 100px;
      height: 100px;
      background: #39394d;
      .van-nav-bar__title{
        font-size: 40px;
        color: #f6d0ab;
      }
      .van-icon{
        font-size: 40px;
        color: #f6d0ab;
      }
      .van-nav-bar__right{
        font-size: 28px;
        color: #f6d0ab;
        .van-icon{
          font-size: 40px;
          color: #f6d0ab;
        }
      }
    }

  }
}
.van-empty {
  margin-top: 150px;
}
::v-deep {
  .van-pull-refresh {
    padding: 30px 40px 40px;
  }
}
.flex_between {
  display: flex;
  justify-content: space-between;
}
.flex {
  width: 100%;
  display: flex;
  margin-bottom: 40px;
  .left {
    width: 40px;
    height: 210px;
    background-color: #39394d;
    padding: 60px 0;
    border-radius: 8px 0px 0px 8px;
    .text {
      width: 24px;
      height: 120px;
      font-size: 24px;
      color: #f6d0ab;
      line-height: 30px;
      margin: auto;
    }
  }
  .right {
    width: 100%;
    background-color: #fff;
    height: 210px;
    padding: 0 20px;
    .orderInfo {
      padding: 10px 0;
      border-bottom: 1px solid #e9e9e9;
      .comeWight {
        padding-top: 6px;
        color: #242424;
        font-size: 32px;
        font-weight: 500;
        line-height: 42px;
      }
      .newWight {
        margin-top: 10px;
        color: #999999;
        font-size: 24px;
        line-height: 30px;
      }
      .state{
        width: 112px;
        height: 56px;
        line-height: 56px;
        font-size: 24px;
        text-align: center;
        border-radius: 6px;
      }
      .stateash{
        background: #F1F3F7;
        color: #8F8F9F;
      }
      .stateGreen{
        background: #E6FFFA !important;
        color: #00AD8C !important;
      }
      .stateBlue{
        background: #E6F4FF !important;
        color: #33A5FF !important;
      }
      .stateYellow{
        background: #FFF2E6 !important;
        color: #FF7A00 !important;
      }
      .BrokenWeight {
        color: #999999;
        font-size: 28px;
        line-height: 34px;
      }
    }
    .orderNmber {
      padding: 6px 0;
      color: #999999;
      font-size: 24px;
    }
  }
}
</style>